<template>
  <div class="contractWaybillPage">
    <div class="facilityBox"></div>
  </div>
</template>

<script>
export default {
  created() {
    this.$router.push("/platform/sceneWayBill");
  },
};
</script>

<style></style>
